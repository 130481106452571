import React from 'react';
// import sectiondata from '../../data/sections.json';

const Contacto = () => {
    return <div>
                        
                    
                
            
    </div>
}

export default Contacto