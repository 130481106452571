import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
            <div>
                <nav className="navbar navbar-area navbar-expand-lg nav-style-01">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <div className="logo-wrapper mobile-logo">
                                <a href={anchor} className="logo">
                                    <img src={publicUrl + 'assets/img/logo.png'} alt={imgattr} />
                                </a>
                            </div>

                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#Riyaqas_main_menu"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggle-icon">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
                            <div className="logo-wrapper desktop-logo">
                                <a href="/" className="logo">
                                    <img src={publicUrl + 'assets/img/logo.png'} alt={imgattr} />
                                </a>
                            </div>
                            <ul className="navbar-nav">
                                <li className="menu-item-has-children active-item" data-section="header-area">
                                    <Link to={'/'}>Inicio</Link>
                                </li>
                                <li className="menu-item-has-children active-item" data-section="service-area">
                                    <Link to="/">Servicios</Link>
                                </li>

                                <li className="menu-item-has-children active-item" data-section="sba-work-area">
                                    <a href="/">Nosotros</a>
                                </li>

                                <li className="menu-item-has-children active-item" data-section="sba-screenshot-area">
                                    <Link to="/">Aplicación</Link>
                                </li>
      

                                <li className="menu-item-has-children active-item" data-section="pricing-page-area">
                                    <a href="/">Precio</a>

                                </li>


                                {/* <li className="menu-item-has-children active-item" data-section="sba-client-area">
                                    <a href="/">Testimonio</a>

                                </li> */}


                                <li className="menu-item-has-children active-item" data-section="share-button">
                                    <a href="/">Contacto</a>
                                </li>

                                <li className="menu-item-has-childre" >
                                    <a href="https://cloud.nutribe.io/" target="_blank" rel="noopener noreferrer">Iniciar sesión</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </nav>

                <div className="body-overlay" id="body-overlay"></div>
                <div className="search-popup" id="search-popup">
                    <form action="index.html" className="search-form">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Search....." />
                        </div>
                        <button type="submit" className="submit-btn"><i className="fa fa-search"></i></button>
                    </form>
                </div>
            </div>
        )
    }
}


export default Navbar